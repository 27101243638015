<template>
  <facility-transactions-form />
</template>

<script>
import FacilityTransactionsForm from "@/components/transactions/FacilityTransactionsForm";

export default {
  components: {
    FacilityTransactionsForm,
  },
};
</script>
