<template>
  <div>
    <div class="serviceHeader">
      <div class="font-size-36 font-weight-bold text-dark mb-4">
        Transactions
      </div>
    </div>

    <a-tabs :default-active-key="payment_mode" @change="onPaymentModeChanged">
      <a-tab-pane key="all">
        <span slot="tab">
          <a-icon type="bar-chart" />
          All
        </span>

        <a-spin :spinning="loading">
          <facility-transactions-listing
            :tableData="transactions"
            :pagination="getPagination"
            @paginationChange="onPaginationUpdate"
          />
        </a-spin>
      </a-tab-pane>

      <a-tab-pane key="electronic">
        <span slot="tab">
          <a-icon type="wallet" />
          Electronic
        </span>

        <a-spin :spinning="loading">
          <facility-transactions-listing
            :tableData="transactions"
            :pagination="getPagination"
            @paginationChange="onPaginationUpdate"
          />
        </a-spin>
      </a-tab-pane>

      <a-tab-pane key="cash">
        <span slot="tab">
          <a-icon type="dollar" />
          Cash
        </span>

        <a-spin :spinning="loading">
          <facility-transactions-listing
            :tableData="transactions"
            :pagination="getPagination"
            @paginationChange="onPaginationUpdate"
          />
        </a-spin>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { get, isEmpty } from "lodash";
import { GET_TRANSACTIONS } from "@/store/actions";

import { getAllTransactions } from "@/services/api/account";

import ProfileMixin from "@/mixins/Profile";
import FacilityTransactionsListing from "./FacilityTransactionsListing.vue";

export default {
  components: {
    FacilityTransactionsListing,
  },
  mixins: [ProfileMixin],
  data() {
    return {
      loading: false,

      transactions: [],
      transaction: {},
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
      payment_mode: "all",
    };
  },
  computed: {
    orders() {
      return this.transactions
        .map((transaction) => {
          return transaction.orders.map((orders) => {
            return {
              ...transaction,
              transaction_id: transaction._id,
              ...orders,
            };
          });
        })
        .flat();
    },
    getPagination() {
      return {
        current: this.currentPage,
        limit: this.pageSize,
        total: this.totalPages,
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        hideOnSinglePage: true,
      };
    },
  },
  created() {
    this.queryTransactions();
  },
  methods: {
    ...mapActions("tourist", {
      fetchTransactions: GET_TRANSACTIONS,
    }),
    onPaginationUpdate({ current, pageSize }) {
      this.currentPage = current;
      this.pageSize = pageSize;
      this.queryTransactions();
    },
    getValueFromSource(source, path, defaultValue = "") {
      return get(source, path, defaultValue);
    },
    getStatusColor(status) {
      return status === "success" ? "green" : "yellow";
    },
    viewTransactions(transaction) {
      this.$router.push({
        path: `/tourist/transaction/${transaction.transaction_id}`,
      });
    },
    getServiceTitle(data) {
      if (data.service) {
        if (data.service.title) {
          return data.service.title || "";
        } else {
          if (data.service.vehicle_model || data.service.vehicle_type) {
            return `Transport Rental - ${data.service.vehicle_model} - ${data.service.vehicle_year}`;
          }
        }
      }
      return "";
    },
    onPaymentModeChanged(payment_mode) {
      if (payment_mode !== this.payment_mode) {
        this.payment_mode = payment_mode;
        this.currentPage = 1;
        this.queryTransactions();
      }
    },
    async queryTransactions() {
      try {
        this.loading = true;

        const query = {
          page: this.currentPage,
          limit: this.pageSize,
          payment_mode: this.payment_mode,
        };

        const response = await getAllTransactions(query);
        const { data, pagination } = response;

        this.transactions = data;

        const { totalRecords } = pagination;
        this.totalPages = totalRecords;

        const hasPreviousQuery = this.$route.query;

        if (!isEmpty(hasPreviousQuery)) {
          this.$router.replace({ query: {} }).catch((e) => {});
        }

        const urlQuery = {
          page: query.page,
          limit: query.limit,
        };

        this.$router
          .replace({
            query: urlQuery,
          })
          .catch((e) => {});
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: `Error fetching transactions`,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.ant-input.ant-input-disabled {
  background-color: white;
}
</style>
